/* Global Styling so Footer stays at bottom relative to content*/

html, body {
   height: 100%;
   background-color: floralwhite;
}

#body {
   display: flex;
   flex-direction: column;
   background-color: floralwhite;
}

#content {
   flex: 1 0 auto;
}

/* Header Styling */

#top {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   background-color: floralwhite;
   z-index: 99;
}

#banner {
   display: flex;
   justify-content: space-around;
   margin-top: 1em;
   margin-bottom: 1em;
   font-size: x-large;
   background-color: floralwhite;
}

.bannerSelector {
   text-decoration: none;
   color: rgb(12, 108, 187);
   font-weight: 500;
}

@media screen and (max-width: 770px) {
   .bannerSelector {
      font-size: medium;
      font-weight: 400;
   }
}

/* Home Page Styling */

#homePage {
   display: flex;
   margin: 7em 0em 0em 0em;
   padding: 0;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: rgb(12, 108, 187);
   background-color: floralwhite;
}

@media screen and (max-width: 770px) {
   #homePage {
      flex-direction: column-reverse;
   }
}

.image-blurred-edge {
   background-image: url(./media/Profile.png);
   width: 372px;
   height: 472px;
   border-radius: 5em;
   box-shadow: 0 0 8px 8px white inset;
   margin-right: 3em;
}

@media screen and (max-width: 770px) {
   .image-blurred-edge {
      align-content: center;
      margin: 0em;
      width: 250px;
      height: 317px;
   }
}

#homeName {
   margin-left: 2em;
   font-size: 2em;
   font-weight: 700;
}

#homePageQuote {
   margin-left: 3em;
   font-size: 1.5em;
   font-weight: 500;
  }

@media screen and (max-width: 770px) {
   #homeName {
      align-content: center;
      margin-left: 0em;
      font-size: 1.5em;
   }
}

@media screen and (max-width: 770px) {
   #homePageQuote {
      align-content: center;
      margin-left: 0em;
      font-size: 1em;
      margin-bottom: 1em;
   }
}

/* About page styling */

#aboutPage {
   margin: 2em 1em 1em 1em;
   padding: 2em;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: rgb(12, 108, 187);
}

#aboutContent {
   padding-left: 9em;
   padding-right: 9em;
   font-size: 1.2em;
   font-weight: 500;
}


@media screen and (max-width: 770px) {
   #aboutContent {
      padding-left: .3em;
      padding-right: .3em;
      font-size: .8em;
      font-weight: 400;
   }
}

/* Projects page styling */

#projectsPage {
   margin: 2em 1em 1em 1em;
   padding: 1em;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   color: rgb(12, 108, 187);
}

@media screen and (max-width: 770px) {
   #projectsPage {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
     }
}

h6 {
   margin: .1em;
   text-align: center;
}
.projectLabel {
   margin-bottom: none;
   text-align: center;
   font-size: 1.5em;
   font-weight: 500;
   align-items: center;
}

label {
   margin: 2em;
}

#githubWork {
   flex-direction: column;
   margin: 2em;
   align-content: center;
}

/* Hobby page styling */

#hobbyPage {
   margin: 2em 1em 1em 1em;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: rgb(12, 108, 187);
}

@media screen and (max-width: 770px) {
   #hobbyPage {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
     }
}

.hobbyLabel {
   margin-top: 2em;
   margin-bottom: 1em;
   text-align: center;
   font-size: 1.5em;
   font-weight: 500;
}

/* Work page styling */

#workPage {
   margin: 2em 1em 1em 1em;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: rgb(12, 108, 187);
}

.workLabel {
   margin-top: 2em;
   margin-bottom: 2em;
   text-align: center;
   font-size: 1.2em;
   font-weight: 500;
}

/* modal styling */

#main {
   display: flex;
   flex-direction: column;
   justify-items: center;
   align-items: center;
}

#modal {
   display: flex;
   flex-direction: column;
   align-self: center;
   justify-self: center;
   width: 90vw;
   height: 90vh;
   z-index: 3000;
   position: absolute;
   top: 0em;
   background-color: rgba(92, 73, 33, 0.924);
   justify-items: center;
   align-items: center;
   margin: 1% 5% 9%;
   padding: 0em;
   color: white;
}

/* Form Styling */

#form {
width: 250px;
height: 500px;
align-content: flex-start;
}

.form-label {
   margin-left: 5px;
}

.form-control {
   width: 250px;
}

#message {
   height: 5em;
}

#submit-button {
   align-self: center;
}

/* Footer Styling */

#bottom {
   flex-shrink: 0;
   width: 100%;
   background-color: #FFF;
   z-index: 99;
}

#footer {
   display: flex;
   justify-content: space-around;
   margin-top: 1em;
   margin-bottom: 1em;
   font-size: x-large;
   background-color: floralwhite;
}

.footerSelector {
   text-decoration: none;
   color: rgb(12, 108, 187);
   font-weight: 500;
}

@media screen and (max-width: 770px) {
   .footerSelector {
      font-size: medium;
      font-weight: 400;
   }
}